import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const lead_list = getBaseUrl() + "/admin/getAllLeads";
const user_delete = getBaseUrl() + "/admin/deleteLead";
const user_update = getBaseUrl() + "/user/updatelead";
const user_add = getBaseUrl() + "/user/addlead";
// this is update api of the lead
const lead_status = getBaseUrl() + "/admin/updateMultipleLead";
export const user_add_api = async (data) => {
  let config = {
    method: "post",
    url: user_add,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const user_update_api = async (data) => {
  let config = {
    method: "post",
    url: user_update,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const lead_status_api = async (data, id) => {
  let config = {
    method: "patch",
    url: lead_status,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const user_delete_api = async (data) => {
  let config = {
    method: "DELETE",
    url: user_delete + `/${data.id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllLead_api = async (data) => {
  //--------------------------------------------
  let config = {
    method: "post",
    // url:
    //   lead_list +
    //   `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    url: lead_list,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
